const easeInOutQuart = (t: number): number => {
  return t < 0.5 
    ? 8 * t * t * t * t 
    : 1 - 8 * (--t) * t * t * t;
};

export const scrollToSection = (sectionId: string | undefined): void => {
  if (!sectionId) {
    console.warn('No section ID provided to scrollToSection');
    return;
  }

  const element = document.getElementById(sectionId);
  if (!element) {
    console.warn(`Element with ID "${sectionId}" not found`);
    return;
  }

  const start = window.pageYOffset;
  const target = element.getBoundingClientRect().top + start;
  const duration = 800;
  const startTime = performance.now();

  const animate = (currentTime: number) => {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1);
    
    window.scrollTo(0, start + (target - start) * easeInOutQuart(progress));
    
    if (progress < 1) {
      requestAnimationFrame(animate);
    }
  };

  requestAnimationFrame(animate);
};
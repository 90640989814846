import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase/config';
import { authService } from './service';
import { logInfo, logError } from '../utils/logger';
import type { User, AuthState } from './types';

interface AuthContextType extends AuthState {
  loginWithEmail: (email: string, password: string) => Promise<void>;
  loginWithGoogle: () => Promise<void>;
  register: (email: string, password: string, name: string) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<AuthState>({
    user: null,
    loading: true,
    error: null,
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
          const userData = userDoc.data();

          const authUser: User = {
            ...firebaseUser,
            uid: firebaseUser.uid,
            email: firebaseUser.email || '',
            displayName: firebaseUser.displayName || '',
            photoURL: firebaseUser.photoURL || '',
            role: userData?.role || 'user',
            metadata: {
              lastSignInTime: firebaseUser.metadata.lastSignInTime || null,
              creationTime: firebaseUser.metadata.creationTime || null
            }
          };

          setState({
            user: authUser,
            loading: false,
            error: null,
          });

          logInfo('Usuario autenticado', { userId: firebaseUser.uid });
        } catch (error) {
          logError(error as Error, {
            context: 'Cambio de Estado de Autenticación',
            userId: firebaseUser.uid,
          });
          setState({ user: null, loading: false, error: 'Error al cargar datos del usuario' });
        }
      } else {
        setState({ user: null, loading: false, error: null });
      }
    });

    return () => unsubscribe();
  }, []);

  const loginWithEmail = async (email: string, password: string) => {
    setState((prev) => ({ ...prev, loading: true, error: null }));
    try {
      const user = await authService.loginWithEmail({ email, password });
      setState((prev) => ({ ...prev, user, loading: false }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Credenciales inválidas';
      setState((prev) => ({ ...prev, loading: false, error: errorMessage }));
      throw error;
    }
  };

  const loginWithGoogle = async () => {
    setState((prev) => ({ ...prev, loading: true, error: null }));
    try {
      const user = await authService.loginWithGoogle();
      setState((prev) => ({ ...prev, user, loading: false }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Error al iniciar sesión con Google';
      setState((prev) => ({ ...prev, loading: false, error: errorMessage }));
      throw error;
    }
  };

  const register = async (email: string, password: string, name: string) => {
    setState((prev) => ({ ...prev, loading: true, error: null }));
    try {
      const user = await authService.register({ email, password, name });
      setState((prev) => ({ ...prev, user, loading: false }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Error al crear la cuenta';
      setState((prev) => ({ ...prev, loading: false, error: errorMessage }));
      throw error;
    }
  };

  const logout = async () => {
    setState((prev) => ({ ...prev, loading: true, error: null }));
    try {
      await authService.logout();
      setState({ user: null, loading: false, error: null });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Error al cerrar sesión';
      setState((prev) => ({ ...prev, loading: false, error: errorMessage }));
      throw error;
    }
  };

  const value: AuthContextType = {
    ...state,
    loginWithEmail,
    loginWithGoogle,
    register,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe ser utilizado dentro de un AuthProvider');
  }
  return context;
};

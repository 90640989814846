import ReactGA from 'react-ga4';
import { logInfo, logError } from '../utils/logger';
import type { TrackingEvent } from './types';

const GA_TRACKING_ID = import.meta.env.VITE_GA_TRACKING_ID;

export const initializeGA = () => {
  if (!GA_TRACKING_ID) {
    logError(new Error('Google Analytics tracking ID not found'));
    return;
  }

  try {
    ReactGA.initialize(GA_TRACKING_ID);
    logInfo('Google Analytics initialized successfully');
  } catch (error) {
    logError(error as Error, { context: 'GA Initialization' });
  }
};

export const trackGAEvent = (event: TrackingEvent) => {
  if (!GA_TRACKING_ID) return;

  try {
    ReactGA.event({
      category: event.category,
      action: event.action,
      label: event.label,
      value: event.value
    });
    logInfo('GA event tracked', event);
  } catch (error) {
    logError(error as Error, { context: 'GA Event Tracking', event });
  }
};

export const trackGAPageView = (path: string) => {
  if (!GA_TRACKING_ID) return;

  try {
    ReactGA.send({ hitType: "pageview", page: path });
    logInfo('GA pageview tracked', { path });
  } catch (error) {
    logError(error as Error, { context: 'GA Pageview Tracking', path });
  }
};
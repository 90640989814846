import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyC0PROiHUm_u9fXjMwVuK1pjq8bzlYc85U",
  authDomain: "web-pedro-3b4b3.firebaseapp.com",
  projectId: "web-pedro-3b4b3",
  storageBucket: "web-pedro-3b4b3.firebasestorage.app",
  messagingSenderId: "1069711388232",
  appId: "1:1069711388232:web:75b6b25df3698b72abf27d",
  measurementId: "G-HQ0W8GPE5P",
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Inicializa servicios específicos de Firebase
const auth = getAuth(app);
const db = getFirestore(app);

// Exporta las instancias
export { auth, db };

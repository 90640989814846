import { useContext } from 'react';
import { AuthContext } from '../context';
import { logError } from '../../utils/logger';

export const useAuth = () => {
  const context = useContext(AuthContext);
  
  if (!context) {
    const error = new Error('useAuth debe ser utilizado dentro de un AuthProvider');
    logError(error, { context: 'Auth Hook Usage' });
    throw error;
  }
  
  return context;
};
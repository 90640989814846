import { z } from 'zod';
import { auth } from '../firebase/config';
import { getIdToken } from 'firebase/auth';

export const LoginSchema = z.object({
  email: z.string().email('Email inválido'),
  password: z.string().min(6, 'La contraseña debe tener al menos 6 caracteres'),
  rememberMe: z.boolean().optional()
});

export const registerSchema = z.object({
  email: z.string().email('Email inválido'),
  password: z.string().min(6, 'La contraseña debe tener al menos 6 caracteres'),
  confirmPassword: z.string().min(6, 'La confirmación debe coincidir con la contraseña'),
  name: z.string().min(2, 'El nombre debe tener al menos 2 caracteres'),
  terms: z.boolean().refine((value) => value === true, {
    message: 'Debes aceptar los términos y condiciones',
  }),
}).refine((data) => data.password === data.confirmPassword, {
  message: 'Las contraseñas no coinciden',
  path: ['confirmPassword'], // Apunta específicamente al campo confirmPassword
});

export type LoginForm = z.infer<typeof LoginSchema>;
export type RegisterForm = z.infer<typeof registerSchema>;

export const verifyToken = async (token: string): Promise<boolean> => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) return false;

    const idToken = await getIdToken(currentUser, true);
    return idToken === token;
  } catch (error) {
    console.error('Error verifying token:', error);
    return false;
  }
};

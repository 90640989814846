import { 
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  type User as FirebaseUser
} from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase/config';
import { logInfo, logError } from '../utils/logger';
import type { AuthUser, AuthCredentials, RegisterData } from './types';

const googleProvider = new GoogleAuthProvider();

const mapUserToAuthUser = async (user: FirebaseUser): Promise<AuthUser> => {
  const userDoc = await getDoc(doc(db, 'users', user.uid));
  const userData = userDoc.data();

  return {
    ...user,
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
    emailVerified: user.emailVerified,
    isAnonymous: user.isAnonymous,
    providerData: user.providerData,
    refreshToken: user.refreshToken,
    role: userData?.role || 'user',
    createdAt: userData?.createdAt,
    lastLogin: userData?.lastLogin,
    metadata: {
      lastSignInTime: user.metadata.lastSignInTime || null,
      creationTime: user.metadata.creationTime || null
    }
  };
};

export const authService = {
  async loginWithEmail({ email, password }: AuthCredentials): Promise<AuthUser> {
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      await updateUserLastLogin(user.uid);
      
      logInfo('User logged in with email', { email });
      
      return await mapUserToAuthUser(user);
    } catch (error) {
      logError(error as Error, { context: 'Email Login' });
      throw error;
    }
  },

  async loginWithGoogle(): Promise<AuthUser> {
    try {
      const { user } = await signInWithPopup(auth, googleProvider);
      
      // Create or update user document in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        role: 'user',
        lastLogin: new Date().toISOString()
      }, { merge: true });
      
      logInfo('User logged in with Google', { email: user.email });
      
      return await mapUserToAuthUser(user);
    } catch (error) {
      logError(error as Error, { context: 'Google Login' });
      throw error;
    }
  },

  async register({ email, password, name }: RegisterData): Promise<AuthUser> {
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      
      // Create user document in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email,
        displayName: name,
        role: 'user',
        createdAt: new Date().toISOString(),
        lastLogin: new Date().toISOString()
      });
      
      logInfo('User registered successfully', { email });
      
      return await mapUserToAuthUser(user);
    } catch (error) {
      logError(error as Error, { context: 'User Registration' });
      throw error;
    }
  },

  async logout(): Promise<void> {
    try {
      await auth.signOut();
      logInfo('User logged out successfully');
    } catch (error) {
      logError(error as Error, { context: 'Logout' });
      throw error;
    }
  },

  mapUserToAuthUser
};

const updateUserLastLogin = async (uid: string): Promise<void> => {
  await setDoc(doc(db, 'users', uid), {
    lastLogin: new Date().toISOString()
  }, { merge: true });
};
type LogLevel = 'debug' | 'info' | 'warn' | 'error';
const level: LogLevel = import.meta.env.DEV ? 'debug' : 'info';

export const logger = {
  debug: (message: string, context?: Record<string, any>) => {
    if (level === 'debug') {
      console.debug('Debug:', message, context);
    }
  },
  info: (message: string, context?: Record<string, any>) => {
    if (['debug', 'info'].includes(level)) {
      console.info('Info:', message, context);
    }
  },
  warn: (message: string, context?: Record<string, any>) => {
    console.warn('Warning:', message, context);
  },
  error: (error: Error | string, context?: Record<string, any>) => {
    const errorMessage = error instanceof Error ? error.message : error;
    console.error('Error:', errorMessage, context);
  }
};

export const { debug: logDebug, info: logInfo, warn: logWarning, error: logError } = logger;

export default logger;
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Layout from './components/Layout';
import Admin from './pages/Admin';

function App() {
  return (
    <>
      <Helmet>
        <title>Pedro Palomares - Digital Coach</title>
        <meta name="description" content="Pedro Palomares - Digital Coach. Experto en automatización, IA y desarrollo web." />
      </Helmet>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/admin/*" element={<Admin />} />
        </Routes>
      </Layout>
    </>
  );
}

export default App;

import { useAuth } from '../lib/auth/hooks/useAuth';
import DashboardLayout from '../components/dashboard/DashboardLayout';

function Admin() {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <DashboardLayout>
      <h1>Panel de Administración</h1>
    </DashboardLayout>
  );
}

export default Admin; 
import { initializeGA, trackGAEvent, trackGAPageView } from './google';
import { initializeFacebookPixel, trackFacebookEvent } from './facebook';
import { logInfo, logError } from '../utils/logger';
import type { TrackingEvent } from './types';

export const initializeAnalytics = () => {
  try {
    initializeGA();
    initializeFacebookPixel();
    logInfo('Analytics services initialized');
  } catch (error) {
    logError(error as Error, { context: 'Analytics Initialization' });
  }
};

export const trackEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number
) => {
  const event: TrackingEvent = { category, action, label, value };
  
  try {
    trackGAEvent(event);
    trackFacebookEvent(action, { category, label, value });
    logInfo('Event tracked', event);
  } catch (error) {
    logError(error as Error, { context: 'Event Tracking', event });
  }
};

export const trackPageView = (path: string) => {
  try {
    trackGAPageView(path);
    trackFacebookEvent('PageView');
    logInfo('Page view tracked', { path });
  } catch (error) {
    logError(error as Error, { context: 'Page View Tracking', path });
  }
};